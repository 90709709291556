import React, { useEffect, useState } from 'react'
import './auth.css'
import accs from '../../assets/img/accs.svg';
import google_signin from '../../assets/img/elements/google-signin.svg';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import AuthHeader from '../../components/header/AuthHeader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { login, reset } from '../../features/auth/authSlice';
import { formDataFromObject } from '../../utils';
import { cleanupStep1Details } from '../../features/apply_new_connection/applyNewConnectionSlice';
import { cleanPayAmount } from '../../features/bowser/bowserSlice';
import { cleanOtherAmount } from '../../features/other/otherSlice';
 

const SignIn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, isSuccess, isError, isProcessing, message} = useSelector(state => state.auth);
    const [formData, setFormData] = useState({
        j_username: '',
        j_password: '',
        requestTransportType: 'json'
      });

    const { j_username, j_password, requestTransportType } = formData;

    const handleChange = (e) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value
        }))
    }

    const handleSignin = (e) => {
        e.preventDefault();

        console.log(formData);
        const userData = new URLSearchParams();
        userData.append('j_username', j_username);
        userData.append("requestTransportType", "json");
        userData.append("j_password", j_password);

        dispatch(login(userData));
        dispatch(cleanupStep1Details());
        dispatch(cleanPayAmount());
        dispatch(cleanOtherAmount());
    }

    useEffect(() => {
        if (isError) {
            toast.error(message)
        }
        if (isSuccess) {
            toast.success(message)
            navigate('/home');
        }
        return () => {
            dispatch(reset());
        }
    }, [user, isSuccess, isError, message, navigate, dispatch])
    
    return (
        <>
            <AuthHeader />

            <div class="row no-gutters guttrs-xs">

                <div class="col-12 col-md-6 signup-height d-none d-sm-block">
                    <div class="row no-gutters">
                        <div class="col text-center pt3">

                        <img src={accs} alt="accounts" style={{ width: '67%'}} />

                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6 acs-height3">
                    <div class="row no-gutters">
                    <div class="col-md-8 offset-md-2">


                    
                    <div class="card card-nav-tabs card-plain">
                        <div class="card-header card-header-light">
                        <div class="nav-tabs-navigation">
                            <div class="nav-tabs-wrapper">
                            <ul class="nav nav-tabs tabs-place" data-tabs="tabs">
                                <li class="nav-item">
                                    <Link class="nav-link-shy regstr1 active" to="/signin" data-toggle="tab">Login <i class="icon ion-md-arrow"></i></Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link-def regstr" to="/signup" data-toggle="tab">Register <i class="icon ion-md-arrow"></i></Link>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div><div class="card-body">
                        <div class="tab-content text-left">

                        <div class="tab-pane active" id="home">
                            <h4 class="mt2">Welcome back</h4>
                            

                            <div class="row mt2">
                                <div class="col">
                                <form onSubmit={handleSignin}>
                                    <div class="form-group">
                                    <input type="text" name="j_username" value={j_username} onChange={handleChange} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone" required />
                                    </div>

                                    <div class="form-group">
                                    <input type="password" name="j_password" value={j_password} onChange={handleChange} class="form-control" id="Password1" placeholder="Password" required />
                                    </div>
                                
                                    <div class="forgot-pass text-right">
                                        <h6 style={{fontSize: '15px'}}>
                                            <Link to="/forgot-pass" class="tt-initial">
                                                Forgot password ?
                                            </Link>
                                        </h6>
                                    </div>

                                    
                                    {!isProcessing ? (
                                        <button type="submit" class="nav-link-def col">Sign in</button>
                                    ) : (
                                        <button style={{'textTransform': 'none'}} class="nav-link-light disabled col" disabled>
                                            <CircularProgress size={15} color="inherit" />&nbsp; Signing in
                                        </button>
                                    )}

                                </form>
                                </div>
                            </div>
                            </div>

                            <div class="tab-pane" id="updates">
                            <h4 class="water-blue mt1">Register</h4>

    

                            <div class="row mt2">
                                <div class="col">
                                <form>
                                    <div class="form-group">
                                    <input type="text" name="usrFirstName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Full Name" required/>
                                    </div>

                                    <div class="form-group">
                                    <input type="phone" name="usrMobileNumber" class="form-control" id="phone" placeholder="Phone number" required/>
                                    </div>

                                    <div class="form-group">
                                    <input type="password" name="usrEncryptedPassword" class="form-control" id="Password1" placeholder="Password" required/>
                                    </div>
                                    <div class="form-group">
                                    <input type="password" name="usrEncryptedPasswordAlt" class="form-control" id="Password2" placeholder="Confirm password" required/>
                                    </div>

                                    <div class="form-check mt2">
                                    <input type="checkbox" class="form-check-input" id="terms" />
                                    <label class="form-check-label" for="terms">I Accept the<a href="#">Terms & Conditions</a></label>
                                    </div>

                                    <button type="submit" class="mt2 nav-link-def col">Sign up</button>

                                </form>
                                </div>
                            </div>

                            </div>

                        </div>
                        </div></div>
                    

                    </div>
                    </div>
                </div>

            </div>

            <Footer />

        </>
    )
}

export default SignIn