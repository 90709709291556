import React, { useEffect, useState } from "react";
import acc_avatar from "../../assets/img/acc-avatar.svg";
import {
  Divider,
  List,
  ListItemButton,
  Menu,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAccounts } from "../../features/Account/accSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getMeter, saveMeter } from "../../features/meter-reading/meterSlice";
import jisomee from "../../assets/apply_new_connection.svg";
import { formatMsToDate, getCurrentDate } from "../../utils";
import CardSkeleton from "../../components/CardSkeleton";
import incident from "../../img/colored/report_incident.svg";
import Dragger from "antd/es/upload/Dragger";
import { Form, Spin } from "antd";
import { postFormData } from "../../features/meter-image/meterImageSlice";

const JisomeeMeter = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [form] = Form.useForm();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [location, setLocation] = useState("");

  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { accounts, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.acc
  );

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    console.log("index", accounts[index].wacAccNumber);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const saveMeterNumber = () => {
    toast.success("Sent !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    currentReading: "",
  });
  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const { user } = useSelector((state) => state.auth);

  const saveMeterReading = async (event) => {
    event.preventDefault();
    if (!formData?.currentReading) {
      toast.error("Please provide meter reading");
      return;
    }
    // if (!latitude || !longitude) {
    //   toast.error("Click send current location from location field");
    //   return;
    // }

    const _formData = {
      mtrCurrentReading: formData?.currentReading,
      mtrUsrId: user.usrId,
      mtrWacAccNumber: accounts[selectedIndex]?.wacAccNumber,
      mtrWtrId: accounts[selectedIndex]?.wacWtrId,
      mtrAccId: accounts[selectedIndex]?.wacAccId,
      mtrDate: getCurrentDate(),
      mtrLat: latitude,
      mtrLong: longitude,
    };
    const res = await dispatch(saveMeter(_formData));
    if (res?.type == "applicationDetails/saveMeter/fulfilled") {
      setFormData({
        currentReading: "",
      });
      setImageUrl("");
      setLocation("");
      setLatitude("");
      setLongitude("");
    }
    dispatch(
      getMeter({
        mtrWacAccNumber: accounts[selectedIndex]?.wacAccNumber,
        // mtrWacAccNumber:{accounts[selectedIndex].wacCMtrNo},
      })
    );
    toast.success("Details saved successfully")
  };

  const [imageLoading, setImageLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
	const [loading, setloading] = useState(true);
 
  const uploadFile = async (e) => {
    setImageLoading(true);
    const formData = new FormData();

    formData.append("file", e.target.files[0]);
    formData.append("description", "meter image web");
    formData.append("wacWtrId", accounts[selectedIndex]?.wacWtrId);

    const res = await dispatch(postFormData(formData));
    if (res?.error) {
      toast.error(res?.error?.message);
    } else {
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      // toast.success("Image saved successfully");
    }
    setImageLoading(false);
  };

  // function getLocation() {
  //   console.log("reaching function");
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       let lat = position.coords.latitude;
  //       let long = position.coords.longitude;
  //       setLatitude(lat);
  //       setLongitude(long);
  //       setLocation(lat + "," + long);
  //       toast.success("Geolocation picked");
  //     });
  //   } else {
  //     toast.error("Geolocation is not supported by this browser.");
  //   }
  // }

  useEffect(() => {
    dispatch(
      getAccounts({
        usrId: user.usrId,
        //  usrId: 2022
      })
    );
    if (isError) {
      console.log(message);
    }
    if (accounts?.length < 1) {
      navigate("/wizard")
  }
  }, [navigate, isError, message, dispatch]);

  const { meter, isMeterLoading, isMeterError, isMeterSuccess } = useSelector(
    (state) => state.meter
  );

  useEffect(() => {
    if (isMeterError) {
      console.log(message);
    }
    dispatch(
      getMeter({
        mtrWacAccNumber: accounts[selectedIndex]?.wacAccNumber,
        // mtrWacAccNumber:{accounts[selectedIndex].wacCMtrNo},
      })
    );
  }, [navigate, isMeterError, selectedIndex, message, dispatch]);

  return (
    <main
      role="main"
      className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 main-window"
    >
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-1">
        <h4 className="page-intros">Jisomee meter</h4>
      </div>

      {meter == null ? (
        <p>No data</p>
      ) : (
        <>
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              {accounts.length && (
                <div className="row main-deck">
                  <div className="col-12 col-md-6">
                    <div className="card">
                      <h4 className="dash-intro col water-blue">
                        Jisomee Meter
                      </h4>
                      <div className="card-body mt1">
                        <div className="wrapper-demo">
                          <List
                            component={Paper}
                            aria-label="Device settings"
                            sx={{ p: 2 }}
                            onClick={handleClickListItem}
                          >
                            <ListItemButton
                              id="lock-button"
                              aria-haspopup="listbox"
                              aria-controls="lock-menu"
                              aria-label="when device is locked"
                              aria-expanded={open ? "true" : undefined}
                            >
                              <Stack
                                direction="row"
                                divider={
                                  <Divider orientation="vertical" flexItem />
                                }
                                spacing={2}
                                primary="When device is locked"
                                secondary={accounts[selectedIndex]}
                              >
                                <Typography>
                                  {accounts[selectedIndex].wacAccNumber}
                                </Typography>
                                <Typography>
                                  {accounts[selectedIndex].wacCustName}
                                </Typography>
                              </Stack>
                              <ArrowDropDownIcon sx={{ ml: 2 }} />
                            </ListItemButton>
                          </List>
                          <Menu
                            id="lock-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            sx={{ m: 2, p: 3.5 }}
                            MenuListProps={{
                              "aria-labelledby": "lock-button",
                              role: "listbox",
                            }}
                          >
                            {accounts.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                sx={{ m: 2, p: 2, my: 1 }}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                <Stack
                                  direction="row"
                                  divider={
                                    <Divider orientation="vertical" flexItem />
                                  }
                                  spacing={3}
                                >
                                  <Typography>{option.wacAccNumber}</Typography>
                                  <Typography>{option.wacCustName}</Typography>
                                </Stack>
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>

                        <div className="mt1">
                          <div className="row">
                            <div className="col">Meter serial number:</div>
                            <div className="col">
                              {" "}
                              {accounts[selectedIndex].wacCMtrNo}
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div className="the-form mt2">
                          <h6>Submit info of your meter below</h6>

                          <form className="mt2">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="currentReading"
                                aria-describedby="currentReading"
                                placeholder="Current meter reading"
                                onChange={handleChange}
                                value={formData.currentReading}
                                name="currentReading"
                              />
                            </div>

                            <div class="form-group mt-2">
                              <label for="location">Meter image</label>
                              <div class="custom-file">
                                <input
                                  type="file"
                                  name="file"
                                  accept="image/*"
                                  onChange={uploadFile}
                                  class="custom-file-input"
                                  id="img"
                                  required
                                />
                                <label
                                  class="custom-file-label"
                                  id="file-label"
                                  for="location"
                                  style={{
                                    border: "none !important",
                                    borderBottom: "1px solid #ccc",
                                  }}
                                >
                                  Issue support image
                                </label>
                              </div>

                              {imageUrl && (
                                <div className="mt-2">
                                  <p>Preview:</p>
                                  <img
                                    src={imageUrl}
                                    alt="Uploaded"
                                    style={{ maxWidth: "100%", height: "15vh" }}
                                  />
                                </div>
                              )}
                            </div>

                            

                            {/* <div className="form-group mt-2">
                              <label for="location">Location</label>
                              <div
                                style={{
                                  borderRadius: " 8px 8px 0px 0px",
                                  border: "1px solid #000",
                                  height: "350px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: "20px",
                                  }}
                                >
                                  <button
                                    onClick={getLocation}
                                    type="button"
                                    style={{
                                      borderRadius: "46px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "253px",
                                      padding: "8px",
                                    }}
                                    className="button"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M12 10.7981C12.3956 10.7981 12.7822 10.6808 13.1111 10.4611C13.44 10.2413 13.6964 9.92895 13.8478 9.5635C13.9991 9.19805 14.0387 8.79592 13.9616 8.40795C13.8844 8.01999 13.6939 7.66363 13.4142 7.38392C13.1345 7.10422 12.7781 6.91373 12.3902 6.83656C12.0022 6.75939 11.6001 6.799 11.2346 6.95038C10.8692 7.10175 10.5568 7.3581 10.3371 7.68699C10.1173 8.01589 10 8.40257 10 8.79813C10 9.32857 10.2107 9.83728 10.5858 10.2123C10.9609 10.5874 11.4696 10.7981 12 10.7981ZM11.29 17.7081C11.383 17.8019 11.4936 17.8763 11.6154 17.927C11.7373 17.9778 11.868 18.0039 12 18.0039C12.132 18.0039 12.2627 17.9778 12.3846 17.927C12.5064 17.8763 12.617 17.8019 12.71 17.7081L16.8 13.6081C17.75 12.6587 18.397 11.4488 18.6593 10.1316C18.9216 8.81437 18.7873 7.44894 18.2735 6.20802C17.7597 4.96711 16.8894 3.90644 15.7727 3.16019C14.656 2.41394 13.3431 2.01563 12 2.01562C10.6569 2.01563 9.344 2.41394 8.22731 3.16019C7.11062 3.90644 6.24033 4.96711 5.72652 6.20802C5.2127 7.44894 5.07845 8.81437 5.34073 10.1316C5.60301 11.4488 6.25005 12.6587 7.2 13.6081L11.29 17.7081ZM7.23 8.33813C7.29833 7.62515 7.52502 6.93643 7.89352 6.32225C8.26203 5.70807 8.76305 5.18394 9.36 4.78813C10.1442 4.27323 11.0619 3.99889 12 3.99889C12.9381 3.99889 13.8558 4.27323 14.64 4.78813C15.233 5.18259 15.7311 5.70354 16.0987 6.31352C16.4663 6.9235 16.6942 7.60736 16.7659 8.31592C16.8376 9.02448 16.7514 9.74012 16.5135 10.4114C16.2756 11.0827 15.8919 11.6929 15.39 12.1981L12 15.5881L8.61 12.1981C8.10752 11.6978 7.72293 11.0917 7.48421 10.4239C7.24548 9.75621 7.15864 9.04365 7.23 8.33813ZM19 19.9981H5C4.73478 19.9981 4.48043 20.1035 4.29289 20.291C4.10536 20.4786 4 20.7329 4 20.9981C4 21.2634 4.10536 21.5177 4.29289 21.7052C4.48043 21.8928 4.73478 21.9981 5 21.9981H19C19.2652 21.9981 19.5196 21.8928 19.7071 21.7052C19.8946 21.5177 20 21.2634 20 20.9981C20 20.7329 19.8946 20.4786 19.7071 20.291C19.5196 20.1035 19.2652 19.9981 19 19.9981Z"
                                        fill="#EDF8FF"
                                      />
                                    </svg>
                                    <span>Send current location</span>
                                  </button>
                                </div>

                                <iframe
                                  // loading="lazy"
                                  onLoad={() => setloading(false)}
                                  style={{ width: "100%", height: "265px" }}
                                  src={`https://maps.google.com/maps?q=${location}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                                  frameborder="0"
                                  marginheight="0"
                                  marginwidth="0"
                                  title="Current Location Map"
                                ></iframe>

                               
                              </div>

                              {loading ? (
                                  <>
                                    <button
                                    style={{width: '300px',display: 'flex',justifyContent: 'center', marginTop:'10px',padding:'10px',color:'white'}}
                                      className="button"
                                      disabled
                                      type="button"
                                    >
                                      <Spin />
                                      <span className="ml-2">
                                        Loading map... Please wait
                                      </span>
                                    </button>
                                  </>
                                ) : null}
                            </div> */}

                            <button
                              type="submit"
                              className="mt2 button nav-link"
                              style={{ minWidth: "100px" }}
                              onClick={saveMeterReading}
                              disabled={imageLoading}
                            >
                              {imageLoading ? <Spin /> : "Submit"}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="card">
                      <div class="card-body">
                        <h5 class="card-title">My Readings</h5>
                        <div class="row">
                          {isMeterLoading ? (
                            <>
                              <CardSkeleton />
                            </>
                          ) : (
                            <>
                              {meter?.length > 0 &&
                                meter
                                  ?.map((mtr) => (
                                    <>
                                      <div class="container-fluid mt1">
                                        <div class="row for-readings pt1">
                                          <div class="col pb1">
                                            <div class="row">
                                              <div class="col">
                                                <div class="row">
                                                  <div class="col-4 notf-img">
                                                    <img
                                                      src={jisomee}
                                                      alt="jisomee_services"
                                                    />
                                                  </div>
                                                  <div class="col-8 pull-right text-right r-date">
                                                    {mtr?.mtrDate
                                                      ? formatMsToDate(
                                                          mtr?.mtrDate
                                                        )
                                                      : ""}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div class="row mt0">
                                              <div class="col-6 notf">
                                                <p>
                                                  Reading:{" "}
                                                  {mtr?.mtrCurrentReading}
                                                </p>
                                                <p>
                                                  Remarks: {mtr?.mtrRemarks}
                                                </p>
                                              </div>
                                              <div class="col-6">
                                                <div class="row">
                                                  <div class="col text-right">
                                                    <span
                                                      style={{
                                                        color: "#b8b8b8;",
                                                      }}
                                                    >
                                                      <i class="icon ion-md-checkmark-circle"></i>
                                                    </span>
                                                    {mtr?.mtrStatus}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="card-footer ">
                                          <div class="row">
                                            <div class="col-6 text-left">
                                              <a class="nav-link-shy" href="#">
                                                Cancel{" "}
                                                <i class="icon ion-md-arrow"></i>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))
                                  ?.reverse()}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </main>
  );
};

export default JisomeeMeter;
