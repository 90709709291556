// formDataFromObject(obj)
export const formDataFromObject = (obj) => {
  let formData = new FormData();
  for (let key in obj) {
    formData.append(key, obj[key]);
  }
  return formData;
};

// format milliseconds to date(dd-mm-yyyy)
export const formatMsToDate = (ms) => {
  if (!ms) return;
  let date = new Date(ms);
  let year = date.getFullYear();
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let day = ("0" + date.getDate()).slice(-2);
  return `${day}/${month}/${year}`;
};

// export function getCurrentDate(separator = "") {
//   let newDate = new Date();
//   let date = newDate.getDate();
//   let month = newDate.getMonth() + 1;
//   let year = newDate.getFullYear();

//   return `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;
// }

export function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const day = String(today.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const arrCleanUp = (arr, key) => {
  // if (arr.length) {
  //     arr.filter(item => item[key] != 0)
  // }
  return arr.filter((item) => item[key] != 0);
};


export const numberWithCommas = (money) => {
  return Number(money)
    .toLocaleString({
      style: "currency",
      currency: "KES",
    })
    .toLowerCase();
};

export function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}

export function cashConverter(money) {
  return capitalize(
    Number(money).toLocaleString("en-US", {
      style: "currency",
      currency: "KES",
    })
  );
}