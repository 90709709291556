import React from 'react';
import { Link } from 'react-router-dom';
// import Logo from '../../assets/img/logo.svg';
import Logo from '../../img/mombasa_logo.png';

const AuthHeader = () => {

    return (
        <header className="auth-header header_area">
            <div className="main_menu">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container box_1620">

                        <div className="col-12 col-md-4">

                            <div className="row">
                                <div className="col">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <Link className="navbar-brand logo_h" to="/" >
                                        <img src={Logo} alt="water-logo" style={{ width: '70%'}} /></Link>
                                </div>

                                <div className="col text-right">
                                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                            aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-8 text-right">

                            <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                                <ul className="nav navbar-nav menu_nav justify-content-end text-right">
                                    <li className="nav-item"><Link className="nav-link" to="#">help</Link></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default AuthHeader;
