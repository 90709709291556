import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleFailure } from "../utils";
import applyNewConnectionService from "./applyNewConnectionService";
const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,

  isError: false,
  isLoading: false,
  isSuccess: false,

  isErrorDoc: false,
  isLoadingDoc: false,
  isSuccessDoc: false,
  
  isLoadingNewCon:false,

  message: "",
  applicationDetails: [],
  applicationDoc: [],
  step1Details: {
    name: "",
    mobileNumber: "",
    nationalId: "",
    services: "",
    waterProvider: "",
    plot: "",
    kraPin: "",
    aorLandlordPhone: "",
    aorLandLordName: "",
    aorLandlordEmail: "",
    aorHouseNo: "",
    aorPostalAddress: "",
    aorPostalCode: "",
    aorEmailAddress:"",
    aorDwellingType:"",
    aorDwellingNoHse:"",
  },
  navigatable:false,
  isCleanupStep1Details: false,

  singleApplicationDetails: {},
};

//register
export const postApplyNewConnection = createAsyncThunk(
  "applicationDetails/postApplyNewConnection",
  async (formData, thunkAPI) => {
    try {
      return await applyNewConnectionService.postApplyNewConnection(formData);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// getNewConnection
export const getNewConnection = createAsyncThunk(
  "applicationDetails/getNewConnection",
  async (formData, thunkAPI) => {
    try {
      return await applyNewConnectionService.getNewConnection(formData);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// postConnectionDoc
export const postConnectionDoc = createAsyncThunk(
  "applicationDetails/postConnectionDoc",
  async (formData, thunkAPI) => {
    try {
      return await applyNewConnectionService.postConnectionDoc(formData);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const applyNewConnectionSlice = createSlice({
  name: "applicationDetails",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.isCleanupStep1Details = false;
    },
    setStep1Details: (state, action) => {
      state.step1Details = action.payload;
    },
    setNavigatable:(state,action) => {
        state.navigatable = action.payload
    },
    cleanupStep1Details: (state) => {
      state.step1Details = initialState.step1Details;
      state.navigatable  = initialState.navigatable;
      state.singleApplicationDetails = initialState.singleApplicationDetails;
    },
  },
  extraReducers: (builder) => {
    builder

      //  postApplyNewConnection
      .addCase(postApplyNewConnection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postApplyNewConnection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.singleApplicationDetails = action?.payload;
      })
      .addCase(postApplyNewConnection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.singleApplicationDetails = {};
      })

      // getNewConnection
      .addCase(getNewConnection.pending, (state) => {
        state.isLoadingNewCon = true;
      })
      .addCase(getNewConnection.fulfilled, (state, action) => {
        state.isLoadingNewCon = false;
        state.isSuccess = true;
        state.applicationDetails = action.payload;
      })
      .addCase(getNewConnection.rejected, (state, action) => {
        state.isLoadingNewCon = false;
        state.isError = true;
        state.message = action.payload;
      })

      //  postConnectionDoc
      .addCase(postConnectionDoc.pending, (state) => {
        state.isLoadingDoc = true;
      })
      .addCase(postConnectionDoc.fulfilled, (state, action) => {
        state.isLoadingDoc  = false;
        state.isSuccessDoc  = true; 
      })
      .addCase(postConnectionDoc.rejected, (state, action) => {
        state.isLoadingDoc  = false;
        state.isErrorDoc  = true;
        state.message = action.payload;
      });
  },
});

export const { reset, setStep1Details,
    setNavigatable,
     cleanupStep1Details } =
  applyNewConnectionSlice.actions;
export default applyNewConnectionSlice.reducer;
