import './viewBillDialog.css';
import {
    Card,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    IconButton
} from '@mui/material';
import {
    Article,
    Circle,
    Today,
    InsertInvitation,
    Download,
    Assignment,
    ArrowForwardIosSharp,
    AssignmentOutlined
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { reset } from '../../features/view_bill/viewBillSlice';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontWeight: 'bold' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
const ViewBillDialog = ({ open, handleOpenBill, handleCloseBill, monthBill, isLoadingBill }) => {
  
    const dispatch = useDispatch()
    useEffect(() => {
        // console.log("monthBill", monthBill);
    }, [reset, monthBill])

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseBill}
                // fullWidth={true}
                // maxWidth="md"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ borderRadius: 5 }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseBill}>
                    My billing history
                </BootstrapDialogTitle>
      
                {!isLoadingBill &&(
                     <DialogContent dividers>
                     <Grid container spacing={2} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                         <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                             <Grid item xs={8} md={6} style={{ fontWeight: '600' }}  >
                                 Name
                             </Grid>
                             <Grid item xs={4} md={6}>
                                 {monthBill?.name}
                             </Grid>
                         </Grid>

                         <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                             <Grid item xs={8} md={6} style={{ fontWeight: '600' }}  >
                              Account No
                             </Grid>
                             <Grid item xs={4} md={6}>
                                 {monthBill?.v_acc_no}
                             </Grid>
                         </Grid>
 
                         <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                             <Grid item xs={8} md={6} style={{ fontWeight: '600' }}>
                                 Current Reading
                             </Grid>
                             <Grid item xs={4} md={6}>
                             {monthBill?.v_current_reading}
                             </Grid>
                         </Grid>
 
                         <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                             <Grid item xs={8} md={6} style={{ fontWeight: '600' }}>
                                 Previous Reading
                             </Grid>
                             <Grid item xs={4} md={6}>
                             {monthBill?.v_previous_reading}
                             </Grid>
                         </Grid>
 
                         <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                             <Grid item xs={8} md={6} style={{ fontWeight: '600' }}>
                                 Consumption
                             </Grid>
                             <Grid item xs={4} md={6}>
                                 {monthBill?.v_invo_mtr_units}
                             </Grid>
                         </Grid>
 
                         <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                             <Grid item xs={8} md={6} style={{ fontWeight: '600' }}>
                                 Bill Amount
                             </Grid>
                             <Grid item xs={4} md={6}>
                             {monthBill?.v_amount}
                             </Grid>
                         </Grid>
 
                         <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                             <Grid item xs={8} md={6} style={{ fontWeight: '600' }}>
                                 Reading Type
                             </Grid>
                             <Grid item xs={4} md={6}>
                             {monthBill?.v_mtr_reading_type?? null}
                             </Grid>
                         </Grid>
 
                         <Grid item xs={12} md={12} style={{ display: 'flex', marginTop: '1rem', fontWeight: 'bold' }}>
                             <Grid item xs={8} md={6} style={{ fontWeight: '600' }}>
                                 BALANCE
                             </Grid>
                             <Grid item xs={4} md={6}>
                             {monthBill?.v_c_acc_bal}
                             </Grid>
                         </Grid>
 
                     </Grid>
 
                 </DialogContent>
                )}
                {isLoadingBill &&(
                    <Skeleton count={10} width={600} />
                )}
      
            
                <DialogActions>
                    <Button sx={{ textTransform: 'capitalize' }}
                        onClick={handleCloseBill}
                        variant="contained"
                        disableElevation
                        color="inherit">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ViewBillDialog