import React, { useEffect, useState } from 'react'
import incident from '../../img/colored/report_incident.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getWaterCompanies } from '../../features/wsp/wspSlice';
import { getBowserRequests, reset, saveBowserRequest } from '../../features/bowser/bowserSlice';
import { CircularProgress, Divider, List, ListItemButton, Menu, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material';
import { formatMsToDate } from '../../utils';
import { getCmIssueCategory, getCrmTickets, saveCrmTicket } from '../../features/report_incidence/reportSlice';
import { getAccounts } from '../../features/Account/accSlice';
import { ArrowDropDown } from '@mui/icons-material';
import CardSkeleton from '../../components/CardSkeleton';


const ReportIncidence = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { waterCompanies, } = useSelector(state => state.wsp);
    const { isProcessing, crmIssues, isLoading, isSuccess,  message, cmIssues } = useSelector(state => state.report)
    const { accounts } = useSelector((state) => state.acc)

 
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const open = Boolean(anchorEl);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [selectedAccount, setSelectedAccount] = useState('')

    const handleMenuItemClick = (e, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        setSelectedAccount(accounts[index].wacAccId)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


 
    const [formData, setFormData] = useState({
        phone: '',
        complaintType: '',
        location: '',
        issue: '',
        wsp: '',
        road: '',
        plotNo: '',
    });

    console.log("formData", formData)

    const { phone, complaintType, location, wsp, issue, road, plotNo } = formData;



    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const report = {
            tkMobile: phone ? phone : user.usrUsername,
            tkContactName: `${user.usrFirstName} ${user.usrLastName}`,
            tkStatus: 'PENDING',
            wacWtrId: wsp,
            tkProblemLocation: location,
            // tkImgUrl: img,
            tkUsrId: user.usrId,
            tkMessage: issue,
            tkIcatId: complaintType,
            tkPlotNo: plotNo,
            tkAccId: selectedAccount ? selectedAccount : accounts[0].wacAccId,
        }

        if (!wsp) {
            toast.warning("Please select water service provider");
        }else if(!complaintType){
            toast.warning("Please select complaint type");
        }
        else {
          const res = dispatch(saveCrmTicket(report));
          console.log("res report",res)
            if (!isProcessing) {
                toast.success("Details saved successfully");
                setFormData((prevState) => ({
                    ...prevState,
                    phone: '',
                    complaintType: '',
                    location: '',
                    issue: '',
                    wsp: '',
                    road: '',
                    plotNo: '',
                }))
            }
        }

    }


    function updateFileName(e) {
        const fileLabel = document.getElementById('file-label');
        if (e.target.files.length > 0) {
            fileLabel.textContent = e.target.files[0].name;
        } else {
            fileLabel.textContent = 'Browse File';
        }
    }

    async function checkData() {
        const res = await dispatch(getWaterCompanies())

    }

    const handleSelectClick = () => {
        checkData();
    }

    async function getIssueCategory() {
        const res = await dispatch(getCmIssueCategory({
            wacWtrId: 1,
        }))

    }

    const handleSelectIssueCategory = () => {
        getIssueCategory();
    }

    useEffect(() => {
        dispatch(getWaterCompanies());
        getIssueCategory();
        dispatch(getAccounts({
            usrId: user.usrId
        }));
 
        return () => {
            dispatch(reset());
        }
    }, [toast, message, dispatch])

    useEffect(()=>{
        dispatch(getCrmTickets({
            wacAccId:selectedAccount ? selectedAccount : accounts[0].wacAccId,
            wacWtrId:1,
        }));
    },[selectedAccount])



    return (
        <>
            <main role="main" class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 main-window">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-1">
                    <h4 class="page-intros">Report issue</h4>
                </div>

                <div class="row main-deck">

                    <div class="col-12 col-md-5">
                        <div class="card">
                            <div class="card-body">


                                <h4 class="water-blue">Report incident</h4>
                                <div class="row">

                                </div>

                                <div class="the-form mt2">
                                    <form onSubmit={handleSubmit} class="mt2">

                                        <div class="form-group">
                                            <label for="phone">Contact phone number</label>
                                            <input type="text" name="phone"
                                                value={phone ? phone : user.usrUsername}
                                                onChange={handleChange} class="form-control"
                                                id="phone" aria-describedby="emailHelp"
                                                placeholder="Contact phone number" />
                                        </div>

                                        <div className="wrapper-demo mb-3">
                                            <Typography className='mb-2'>Select account</Typography>
                                            <List
                                                elevation={0}
                                                component={Paper}
                                                aria-label="Device settings"
                                                sx={{ p: 2, borderBottom: '1px solid #e4e4e4' }}
                                                onClick={handleClickListItem}
                                            >
                                                <ListItemButton
                                                    id="lock-button"
                                                    aria-haspopup="listbox"
                                                    aria-controls="lock-menu"
                                                    aria-label="when device is locked"
                                                    aria-expanded={open ? "true" : undefined}
                                                >
                                                    <Stack
                                                        direction="row"
                                                        divider={
                                                            <Divider orientation="vertical" flexItem />
                                                        }
                                                        spacing={2}
                                                        primary="When device is locked"
                                                        secondary={accounts[selectedIndex]}
                                                    >
                                                        <Typography>
                                                            {accounts[selectedIndex].wacAccNumber}
                                                        </Typography>
                                                        <Typography>
                                                            {accounts[selectedIndex].wacCustName}
                                                        </Typography>
                                                    </Stack>
                                                    <ArrowDropDown sx={{ ml: 2 }} />
                                                </ListItemButton>
                                            </List>
                                            <Menu
                                                id="lock-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                sx={{ m: 2, p: 3.5 }}
                                                MenuListProps={{
                                                    "aria-labelledby": "lock-button",
                                                    role: "listbox",
                                                }}
                                            >
                                                {accounts.map((option, index) => (
                                                    <MenuItem
                                                        key={option}
                                                        selected={index === selectedIndex}
                                                        sx={{ m: 2, p: 2, my: 1 }}
                                                        onClick={(event) =>
                                                            handleMenuItemClick(event, index)
                                                        }
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            divider={
                                                                <Divider orientation="vertical" flexItem />
                                                            }
                                                            spacing={3}
                                                        >
                                                            <Typography>{option.wacAccNumber}</Typography>
                                                            <Typography>{option.wacCustName}</Typography>
                                                        </Stack>
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </div>

                                        <div class="form-group" >
                                            <label for="exampleFormControlSelect1">Complaint Type</label>
                                            <div onFocus={handleSelectIssueCategory}>
                                                <select class="form-control" id="exampleFormControlSelect1" name='complaintType'
                                                    onChange={handleChange}>
                                                    <option value="">-- Select --</option>
                                                    {cmIssues?.length && cmIssues?.map(waterCompany => (
                                                        <option value={waterCompany.icatId} key={waterCompany.icatId}>{waterCompany.icatName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div class="form-group col-md-6">
                                                <label for="phone">Road</label>
                                                <input type="text" name="road"
                                                    value={road}
                                                    onChange={handleChange} class="form-control"
                                                    id="road" aria-describedby="emailHelp"
                                                    placeholder="Road" />
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="phone">Plot Number</label>
                                                <input type="text" name="plotNo"
                                                    value={plotNo}
                                                    onChange={handleChange} class="form-control"
                                                    id="plotNo" aria-describedby="emailHelp"
                                                    placeholder="Plot no." />
                                            </div>
                                        </div>


                                        <div class="form-group mt2">
                                            <label for="location">Message</label>
                                            <textarea type="text" name="issue"
                                                value={issue}
                                                rows="3"
                                                onChange={handleChange} class="form-control" id="issue"
                                                placeholder="issue ..." required />
                                        </div>
                                        {/* <div class="form-group mt-2">
                                            <label for="location">Support Image</label>
                                            <div class="custom-file">
                                                <input
                                                    type="file"
                                                    name="img"
                                                    value={img}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        updateFileName(e);  
                                                    }}
                                                    class="custom-file-input"
                                                    id="img"
                                                    required
                                                />
                                                <label class="custom-file-label" id="file-label" for="location" 
                                                style={{border:'none !important',borderBottom:'1px solid #ccc'}}>
                                                    Issue support image
                                                </label>
                                            </div>
                                        </div> */}



                                        <div class="form-group mt2">
                                            <label for="location">Location</label>
                                            <input type="text" name="location"
                                                value={location}
                                                onChange={handleChange} class="form-control" id="location"
                                                placeholder="eg karen" required />
                                        </div>

                                        <div class="form-group" >
                                            <label for="exampleFormControlSelect1">Water service providers</label>
                                            <div onFocus={handleSelectClick}>
                                                <select class="form-control" id="exampleFormControlSelect1" name='wsp'
                                                    onChange={handleChange}>
                                                    <option value="">-- Select --</option>
                                                    {waterCompanies?.length && waterCompanies?.map(waterCompany => (
                                                        <option value={waterCompany.wtrId} key={waterCompany.wtrId}>{waterCompany.wtrCompany}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>


                                        {!isProcessing ? (
                                            <button type="submit" class="mt1 button nav-link">Submit</button>
                                        ) : (
                                            <button style={{ 'textTransform': 'none' }} class="nav-link-light disabled" disabled>
                                                <CircularProgress size={15} color="inherit" />&nbsp; Submitting
                                            </button>
                                        )}


                                    </form>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-7">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">History</h5>
                                <div class="row">
                                    {isLoading ? (<><CardSkeleton/></>) : (
                                        <>
                                            {crmIssues && crmIssues?.map((report) => (
                                                <>
                                                    <div class="container-fluid mt1">
                                                        <div class="row for-readings pt1">

                                                            <div class="col pb1">
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <div class="row">
                                                                            <div class="col-4 notf-img"><img src={incident} alt="crm_issues" /></div>
                                                                            <div class="col-8 pull-right text-right r-date"> {formatMsToDate(report.tkReportedOn)}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="row mt0">
                                                                    <div class="col-6 notf">
                                                                        <p><span style={{fontWeight:700}} className='bold'>Location:</span> {report.tkProblemLocation}</p>
                                                                     
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="row">
                                                                            <div class="col text-right">
                                                                                <span style={{ color: '#b8b8b8;' }}><i class="icon ion-md-checkmark-circle"></i></span> 
                                                                                {report.tkStatus}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-12'>
                                                                    <p>{report.tkMessage}</p>
                                                                    </div>
                                                                    
                                                                </div>
                                                                
                                                            </div>
                                                        </div>

                                                        <div class="card-footer "> {/* notf-approved */}
                                                            <div class="row">
                                                                <div class="col-6 text-left"><a class="nav-link-shy" href="#">Cancel <i class="icon ion-md-arrow"></i></a></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </main>

        </>
    )
}

export default ReportIncidence