import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import CreateConnection from './pages/apply_for_new_connection';
import Landing from "./pages/landing/landing";
import Profile from './components/Profile/Profile';
import ViewBill from './pages/viewBill/ViewBill';
import NotificationTabs from './pages/notification-tabs/NotificationTabs';
import LipaMaji from "./pages/lipa-maji/LipaMaji";
import JisomeeMeter from "./pages/jisomee-meter/JisomeeMeter";
import SimpleTabs from "./pages/Test";
import StepOneForm from "./pages/apply_for_new_connection/application_details/StepOneForm";
import Fileform from "./pages/apply_for_new_connection/attachments/Fileform";
import SignIn from './pages/auth/SignIn';
import Signup from './pages/auth/Signup';
import Bowser from './pages/bowser/Bowser';
import WithoutNav from './layout/WithoutNav';
import WithNav from './layout/WithNav';
import Exhauster from './pages/exhauster/Exhauster';
import OtherServices from './pages/other_services/OtherServices';
import Home from "./pages/home/Home";
import ResetPass from './pages/auth/ResetPass';
import ForgotPass from './pages/auth/ForgotPass';
import BuyTokens from "./pages/buy-tokens/BuyTokens";
import MajiLoan from "./pages/maji-loan/MajiLoan";

import Account from "./components/account/account";
import Accounts from "./pages/account/Account"
import ReportIncidence from "./pages/report_incident/ReportIncidence";
import Payments from "./components/Payments";
 

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route element={<WithoutNav />}>
          <Route exact path='/' element={<Landing />} />
          <Route exact path='/landing' element={<Landing />} />
          <Route exact path="/signin" element={<SignIn />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/forgot-pass" element={<ForgotPass />} />
          <Route exact path="/reset-pass" element={<ResetPass />} />
          <Route exact path="/signin" element={<SignIn />} /> 
           
        </Route>
        
  
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/wizard" element={<Accounts />} />
 
  
        
        <Route element={<WithNav />}>
          <Route exact path="/view-bill" element={<ViewBill />} />
          <Route exact path="/notifications" element={<NotificationTabs />} />
          {/* <Route path="/connect" element={<CreateConnection />}>
            <Route path="step1" element={<StepOneForm />} />
            <Route path="step2" element={<Fileform />} />
          </Route> */}
           <Route exact path="/connect" element={<CreateConnection />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/bowser" element={<Bowser />} />
          <Route exact path="/exhauster" element={<Exhauster />} />
          <Route exact path="/other-services" element={<OtherServices />} />
          <Route exact path="/jisomee-meter" element={<JisomeeMeter />} />
          <Route exact path="/lipa-maji" element={<LipaMaji />} />  
          <Route exact path="/buy-tokens" element={<BuyTokens/>} />
          <Route exact path="/maji-loan" element={<MajiLoan/>} />
          <Route exact path="/report-incidence" element={<ReportIncidence/>} />
          <Route exact path="/payments" element={<Payments/>} />
          
        </Route>

      </Routes>

    </Router>

  </>
  );
}

export default App;
