import { configureStore, combineReducers} from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import viewBillReducer from '../features/view_bill/viewBillSlice';
import applyNewConnectionReducer from '../features/apply_new_connection/applyNewConnectionSlice';
import notificationReducer from '../features/notifications/notificationSlice';
import accountReducer from '../features/accounts/accountSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import bowserReducer from '../features/bowser/bowserSlice';
import wspReducer from '../features/wsp/wspSlice';
import exhausterReducer from '../features/exhauster/exhausterSlice';
import otherReducer  from '../features/other/otherSlice';
import accountsReducer from '../features/Account/accSlice';
import meterReducer from '../features/meter-reading/meterSlice';
import mpesaReducer from "../features/mpesa/mpesaSlice";
import invoiceReducer from "../features/invoice/invoiceSlice";   
import loaneeReducer from "../features/loanee/loaneeSlice";  
import reportReducer from "../features/report_incidence/reportSlice"; 
import meterImageReducer from "../features/meter-image/meterImageSlice"; 


const persistConfig = {
  key: 'root',
  storage,
}
 
const rootReducer = combineReducers({ 
    auth: authReducer,
    wsp: wspReducer,
    bowser: bowserReducer,
    exhauster: exhausterReducer,
    other: otherReducer,
    periods: viewBillReducer,
    newConnection: applyNewConnectionReducer,
    notifications: notificationReducer,
    acct: accountReducer,
    acc: accountsReducer,
    meter: meterReducer,
    mpesa: mpesaReducer,
    invoice: invoiceReducer,
    loanee:loaneeReducer,
    report:reportReducer,
    meterImage:meterImageReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})

export const persistor = persistStore(store)

