import React, { useEffect } from 'react'
import LipaMaji from '../../img/colored/lipa_maji.svg';
import JisomeeMeter from '../../img/colored/jisomee_meter.svg';
import BuyTokens from '../../img/colored/buy_water_tokens.svg';
import Statement from '../../img/colored/view_bill.svg';
import OtherServices from '../../img/colored/other_services.svg';
import Apply from '../../img/colored/apply_new_connection.svg';
import Bowser from '../../img/colored/bowser_services.svg';
import Exhauster from '../../img/colored/exhauster_services.svg';
import Report from '../../img/colored/report_incident.svg';
import AppBar from '../../components/header/AppBar';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAccounts, reset } from '../../features/Account/accSlice';

const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector(state => state.auth);
    const { accounts, isError, isLoading, message, } = useSelector(state => state.acc);

    useEffect(() => {
        if (!user) navigate('/')
        dispatch(getAccounts())

        return () => { dispatch(reset())  }

    }, [navigate, dispatch])
  return (
    <>
        <AppBar/>
        
        <div class="container-fluid">
            <div class="row">
                <main role="main" class="col col-12 pt-3 px-4 main-window">
         
                <div class="row">
                 
                <div class="col-md-6 offset-md-3 col-12 landing-home">
                   {isLoading ? (<p>Processing ...</p>) : (
                    <>
                    <div class="row mt2 r-mt2">
                    <div class="col-md-3 col-6 main-servs">
                        <Link to="/lipa-maji">
                        <div class="card text-center main-cards">
                            <img class="card-img-top" src={LipaMaji} alt="lipa-maji" />
                            <div class="card-body">
                            <p class="card-text">Lipa Maji</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div class="col-md-3 col-6 main-servs">
                        <Link to="/jisomee-meter"><div class="card text-center main-cards">
                        <img class="card-img-top" src={JisomeeMeter} alt="jisomee-meter" />
                        <div class="card-body">
                            <p class="card-text">Jisomee meter</p>
                        </div>
                        </div></Link>
                    </div>
                    <div class="col-md-3 col-6 main-servs">
                        <Link to="/connect"><div class="card text-center main-cards">
                        <img class="card-img-top" src={Apply} alt="new-connection" />
                        <div class="card-body">
                            <p class="card-text">Apply for new connection</p>
                        </div>
                        </div></Link>
                    </div>
                    {/* <div class="col-md-3 col-6 main-servs">
                        <Link to="/buy-tokens"><div class="card text-center main-cards">
                        <img class="card-img-top" src={BuyTokens} alt="buy-tokens" />
                        <div class="card-body">
                            <p class="card-text">Buy water tokens</p>
                        </div>
                        </div></Link>
                    </div>
                    <div class="col-md-3 col-6 main-servs">
                        <Link to="/maji-loan"><div class="card text-center main-cards">
                        <img class="card-img-top" src={Statement} alt="maji-loan" />
                        <div class="card-body">
                            <p class="card-text">Maji Loan</p>
                        </div>
                        </div></Link>
                    </div> */}
                    </div>

                    <div class="row mt2 r-mt2">
                    <div class="col-md-3 col-6 main-servs">
                        <Link to="/view-bill"><div class="card text-center main-cards">
                        <img class="card-img-top" src={Statement} alt="view-bill" />
                        <div class="card-body">
                            <p class="card-text">View Bill/Statement</p>
                        </div>
                        </div></Link>
                    </div>

                    <div class="col-md-3 col-6 main-servs">
                        <Link to="/bowser"><div class="card text-center main-cards">
                        <img class="card-img-top services" src={Bowser} alt="bowser" />
                        <div class="card-body">
                            <p class="card-text">Bowser Services</p>
                        </div>
                        </div></Link>
                    </div>

                    <div class="col-md-3 col-6 main-servs">
                        <Link to="/exhauster"><div class="card text-center main-cards">
                        <img class="card-img-top services" src={Exhauster} alt="exhauster" />
                        <div class="card-body">
                            <p class="card-text">Exhauster Services</p>
                        </div>
                        </div></Link>
                    </div>

            
                    {/* <div class="col col-lg-3">
                        <Link tp="/help"><div class="card text-center main-cards">
                            <img class="card-img-top" src={Report} alt="report-incident" />
                            <div class="card-body">
                                <p class="card-text">Report Incident</p>
                            </div>
                            </div>
                        </Link>
                    </div> */}


                    </div>

                    <div className='row mt2 r-mt2'>
                        <div class="col-md-3 col-6 main-servs">
                            <Link to="/other-services"><div class="card text-center main-cards">
                            <img class="card-img-top" src={OtherServices} alt="other-services" />
                            <div class="card-body">
                                <p class="card-text">Other Services </p>
                            </div>
                            </div></Link>
                        </div>

                        <div class="col-md-3 col-6 main-servs">
                            <Link to="/report-incidence"><div class="card text-center main-cards">
                                <img class="card-img-top" src={Report} alt="report-incident" />
                                <div class="card-body">
                                    <p class="card-text">Report Incident</p>
                                </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    </>

                    )}
            
                </div>
            
                </div>
            
            
        
            
                </main>
            </div>
        </div>

    </>
  )
}

export default Home