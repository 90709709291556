import { BorderRight } from '@mui/icons-material';
import { alpha, Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react'
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getNewConnection, postApplyNewConnection, reset, setNavigatable, setStep1Details } from '../../../features/apply_new_connection/applyNewConnectionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Create, Delete } from '@mui/icons-material'
import { Card, CardHeader, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { getWaterCompanies } from '../../../features/wsp/wspSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCurrentDate } from '../../../utils'
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const currencies = [
  {
    value: '0',
    label: 'Select service provider',
  },
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
];

const service = [
  {
    value: 'WATER',
    label: 'WATER',
  },
  {
    value: 'SEWER',
    label: 'SEWER',
  },

];

const CssTextField = styled(TextField)({

  '& .MuiInput-underline:after': {
    borderBottomColor: 'rgb(23 135 224 / 50%)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {

      borderTopColor: "#fff",
      borderRightColor: "#fff",
      borderLeftColor: "#fff"
    },

    '&.Mui-focused fieldset': {
      borderColor: 'rgb(23 135 224 / 50%)',
    },
  },
});
export default function StepOneForm({ setValue, value }) {
 
 
  const notifySuccess = () => toast.success(' Successfully saved!', {
    theme: "colored",
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const notifyError = () => toast.error(' Not Saved!', {
    theme: "colored",
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const [currency, setCurrency] = useState("Select you provider");


  const handleChange = (event) => {
    setCurrency(event.target.value);
  };
  const navigate = useNavigate()
  const { step1Details,singleApplicationDetails,applicationDetails } = useSelector((state) => state.newConnection) 
  const [formData, setFormData] = useState(step1Details)
  const { name, mobileNumber, services, waterProvider, nationalId, kraPin, plot,
    aorLandlordPhone,aorLandLordName,aorLandlordEmail,aorHouseNo,aorPostalAddress,aorPostalCode,aorEmailAddress,aorDwellingType,aorDwellingNoHse,
  } = formData

  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth);
  const { waterCompanies, isLoading, isError, isSuccess, message } = useSelector((state) => state.wsp)
  useEffect(() => {
    if (isError) {
      console.log(message)
    }
    dispatch(getWaterCompanies())
  }, [navigate, isError, message, dispatch])

  const [isUpdating, setIsUpdating] = useState(false)

  const onChange = (e) => {
    setIsUpdating(true);
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const saveAndContinue = async (event) => {
    event.preventDefault()
    const _formData = {
      aorId: singleApplicationDetails?.aorId ?  singleApplicationDetails?.aorId : null, 
      aorUsrId: user.usrId,
      aorFullName: name,
      aorPhone: mobileNumber,
      aorServices: services,
      aorWtrId: waterProvider,
      aorNationalId: nationalId,
      aorPin: kraPin,
      aorPlot: plot,
      aorCreatedDate: getCurrentDate(),
      aorStatus: "PENDING",
      aorSubmitStatus: "SUBMITTED",
      aorType:"NEW_CONNECTION",

      aorLandlordPhone:aorLandlordPhone,
      aorLandLordName:aorLandLordName,
      aorLandlordEmail:aorLandlordEmail,
      aorHouseNo:aorHouseNo,
      aorPostalAddress:aorPostalAddress,
      aorPostalCode:aorPostalCode,
      aorEmailAddress:aorEmailAddress,
      aorDwellingType:aorDwellingType,
      aorDwellingNoHse:aorDwellingNoHse,
    }


    if (mobileNumber && name && services && waterProvider && nationalId && kraPin && plot) {
      const res = await dispatch(postApplyNewConnection(_formData))
      if (res?.type === "applicationDetails/postApplyNewConnection/fulfilled") {
        toast.success("Details added successfully")
        setValue(1);
        // dispatch(setNavigatable(true));
        // dispatch(getNewConnection({
        //   wacWtrId: 1,
        //   usrId: user.usrId,
        // }))
      }


    }
    if (!mobileNumber || !name || !services || !waterProvider || !nationalId || !kraPin || !plot) {
      toast.error("Please fill all fields")
      dispatch(setNavigatable(false));
    }

  }
  async function checkData() {
    const res = await dispatch(getWaterCompanies())
  }

  const handleSelectClick = () => {
    checkData();
  }

 


  useEffect(() => {
    if (isUpdating) {
      dispatch(setStep1Details(formData))
    }
    checkData();
  }, [isUpdating, formData])


  return (
    <>

      <Card style={{ wordWrap: "break-word", margin: "15px" }}>
        <CardHeader
          style={{ color: "#4156A6", fontSize: "24px", margin: "8px" }}
          title="Apply for a new connection"

        />
        <div style={{ marginBottom: '10rem', wordWrap: "break-word", margin: "15px" }}>
          <form>
           

            <Grid container spacing={2} style={{ marginBottom: '15px' }}>
            <Grid item xs={12} md={6}>
                <input
                  type="text"
                  class="form-control"
                  id="aorLandLordName"
                  aria-describedby="emailHelp"
                  placeholder="Landlord name"
                  name="aorLandLordName"
                  onChange={onChange}
                  value={aorLandLordName}
                /> 
              </Grid>
              <Grid item xs={6} md={6}>
                <input type="text"
                  class="form-control"
                  placeholder="Mobile number"
                  id="aorLandlordPhone"
                  onChange={onChange}
                  name="aorLandlordPhone"
                  value={aorLandlordPhone}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <input type="email"
                  class="form-control"
                  placeholder="Landloard email"
                  id="aorLandlordEmail"
                  onChange={onChange}
                  name="aorLandlordEmail"
                  value={aorLandlordEmail}  
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                />
              </Grid>

              

            <Grid item xs={6} md={6}>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  aria-describedby="emailHelp"
                  placeholder="Tenant name"
                  name="name"
                  onChange={onChange}
                  value={name}
                /> 
              </Grid>
              <Grid item xs={6} md={6}>
                <input type="text"
                  class="form-control"
                  placeholder="Tenant phone number"
                  id="mobile-number"
                  onChange={onChange}
                  name="mobileNumber"
                  value={mobileNumber}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <input type="email"
                  class="form-control"
                  placeholder="Tenant email"
                  id="aorEmailAddress"
                  onChange={onChange}
                  name="aorEmailAddress"
                  value={aorEmailAddress} 
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" 
                />
              </Grid>
              
              <Grid item xs={6} md={6}>
                <input type="number"
                  class="form-control"
                  placeholder="National ID"
                  onChange={onChange}
                  name="nationalId"
                  value={nationalId} 
                  id="national-id" />
              </Grid>

            </Grid>


            <Grid container spacing={2} style={{ marginBottom: '15px' }}>
              <Grid item xs={6} md={6}>
                <div class="form-group" >
                  <div>
                    <select class="form-control" id="exampleFormControlSelect1" name='services'
                    value={formData?.services}
                      onChange={onChange}>
                      <option disabled value="">-- Select Service--</option>
                      {service?.length && service?.map(option => (
                        <option value={option.value} key={option.value}>{option.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6} md={6}>
              <div class="form-group" >
                  <div onFocus={handleSelectClick}>
                    <select class="form-control" id="exampleFormControlSelect2" 
                    name='waterProvider'
                    value={formData?.waterProvider}
                      onChange={onChange}>
                      <option disabled value="">-- Select Water Provider--</option>
                      {waterCompanies?.length && waterCompanies?.map(option => (
                        <option value={option.wtrId} key={option.wtrId}>{option.wtrCompany}</option>
                      ))}
                    </select>
                  </div>
                </div>
                 
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: '15px' }}>
              <Grid item xs={6} md={6}>
                <input type="text"
                  class="form-control"
                  placeholder="Plot number"
                  name="plot"
                  value={plot}
                  onChange={onChange}
                  id={"plotNumber"} />
              </Grid>
              <Grid item xs={6} md={6}>
                <input type="text"
                  class="form-control"
                  placeholder="House No"
                  name="aorHouseNo"
                  value={aorHouseNo}
                  onChange={onChange}
                  id={"aorHouseNo"} />
              </Grid>
              <Grid item xs={6} md={6}>
                <input type="text"
                  class="form-control"
                  placeholder="KRA Pin"
                  onChange={onChange}
                  name="kraPin"
                  value={kraPin}
                  id="kra pin" />
              </Grid>
              <Grid item xs={6} md={6}>
                <input type="text"
                  class="form-control"
                  placeholder="Postal address"
                  name="aorPostalAddress"
                  value={aorPostalAddress}
                  onChange={onChange}
                  id={"aorPostalAddress"} />
              </Grid>
              <Grid item xs={6} md={6}>
                <input type="text"
                  class="form-control"
                  placeholder="Postal code"
                  name="aorPostalCode"
                  value={aorPostalCode}
                  onChange={onChange}
                  id={"aorPostalCode"} />
              </Grid>

              <Grid item xs={6} md={6}>
              <div class="form-group" > 
                  <select class="form-control" id="exampleFormControlSelect2" 
                    name='aorDwellingType'
                     value={formData?.aorDwellingType}
                      onChange={onChange}>
                      <option disabled value="">-- Select dwelling type--</option>
                      <option  value="MULTI-DWELLING">MULTI-DWELLING</option>
                      <option  value="SINGLE-DWELLING">SINGLE-DWELLING</option>
                      <option  value="YARD TAP">YARD TAP</option>
                    </select>  
                </div>
                 
              </Grid>
              {
                (formData?.aorDwellingType === "MULTI-DWELLING" || formData?.aorDwellingType === "YARD TAP") && (
                  <Grid item xs={6} md={6}>
                  <input type="text"
                    class="form-control"
                    placeholder="Number of houses"
                    name="aorDwellingNoHse"
                    value={aorDwellingNoHse}
                    onChange={onChange}
                    id={"aorDwellingNoHse"} />
                </Grid>
                )
               
              }
            </Grid>
            
            {/* <Grid container spacing={2} style={{ marginBottom: '30px' }}>
            <Grid item xs={12} md={12}>
              <input type="text" 
              class="form-control" 
              id="exampleInputEmail1"
               aria-describedby="emailHelp"
                placeholder="Location"
                name="location"
                id="location"
                onChange={onChange}
                 />
            </Grid>
          </Grid> */}
            <Button
              style={{ backgroundColor: `var(--water-blue)` }}
              variant="contained"
              onClick={saveAndContinue}
            >
              Save and continue
            </Button>
          </form>
        </div>
      </Card>
    </>
  )
}
