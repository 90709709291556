import { Create, Delete } from '@mui/icons-material'
import { Card, CardHeader, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import ApprovalCard from './my_requests/Approved'
import TopRightCard from './my_requests/TopRightCard'
import CreateConnectionTab from './Tab'


export default function CreateConnection() {
    const [prodd,setProdd] = useState()
    return (
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 main-window">
            <h4 className="page-intros">Apply for a new connection</h4>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <CreateConnectionTab />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Card style={{ wordWrap: "break-word", margin: "15px", padding: "1rem" }}>
                        <CardHeader style={{ color: "#2A2A2A", fontSize: "1.25rem", margin: "6px" }}
                            title="My Requests"

                        />
                        <TopRightCard />
                    
                    </Card>
                </Grid>
            </Grid>

           
        </main>
    )
}
