import React, { useEffect, useState } from 'react'
import exhausterservices from '../../img/colored/bowser_services.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getWaterCompanies } from '../../features/wsp/wspSlice';
import { CircularProgress, TextField } from '@mui/material';
import { cashConverter, formatMsToDate } from '../../utils';
import { getExhausterRequests, reset, saveExhausterRequest } from '../../features/exhauster/exhausterSlice';
import { cancelBowserRequests, setExhaustPayment, setPayAmount } from '../../features/bowser/bowserSlice';
import CardSkeleton from '../../components/CardSkeleton';
import DeleteModal from '../../components/DeleteModal';
import { useNavigate } from 'react-router-dom';

const Exhauster = () => {
    const [vehicle, setVehicle] = useState([
        { id: 'PRIVATE', value: 'PRIVATE' },
        { id: 'COMPANY', value: 'COMPANY' },
    ])
    const dispatch = useDispatch();
    const navigate =  useNavigate();
    const { user } = useSelector(state => state.auth);
    const { waterCompanies, } = useSelector(state => state.wsp);
    const { isProcessing, exhausterRequests, isLoading, isSuccess, isError, message } = useSelector(state => state.exhauster)
    const [exhausters, setExhausters] = useState(1);
    const [exhausterPrice, setExhausterPrice] = useState(250);
    const [formData, setFormData] = useState({
        phone: '',
        location: '',
        date: '',
        wsp: '',
        ownership: '',
    });

    const { phone, location, wsp, date, ownership } = formData;

    const handleIncrement = () => {
        ;
        setExhausters(exhausters + 1);
        calcBill();
    }

    const handleDecrement = () => {
        if (exhausters == 1) {
            return;
        }
        setExhausters(exhausters - 1);
        calcBill();
    }

    const calcBill = () => {
        return (exhausterPrice * exhausters);
    }

    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const _exhauster = {
            sreqBowserCount: exhausters,
            sreqContactMobile: user.usrUsername,
            sreqContactName: `${user.usrFirstName} ${user.usrLastName}`,
            sreqStatus: 'PENDING',
            sreqWaterProviderId: wsp,
            sreqLocation: location,
            sreqType: "EXHAUSTER",
            sreqUsrId: user.usrId,
            sreqDateExpected: date,
            sreqVehicleOwnership: ownership
        }

        if (!wsp) {
            toast.warning("Please select water service provider");
        } else if (!ownership) {
            toast.warning("Please select exhauster ownership")
        }
        else {
            dispatch(saveExhausterRequest(_exhauster));
            if (!isProcessing) {
                setExhausters(1);
                setFormData((prevState) => ({
                    ...prevState,
                    phone: '',
                    location: '',
                    date: '',
                    wsp: '',
                    ownership: '',
                }))
            }
        }

    }

    const handlePayments=(money)=>{
        dispatch(setPayAmount(money));
        dispatch(setExhaustPayment(true));
        navigate('/payments')
    }

    const calcPrice = (b) => {
        console.log("b", b)
        console.log("sreqUnitCost", b.sreqUnitCost)
        console.log("sreqBowserCount", b.sreqBowserCount)
        console.log("sreqMileageUnitCost", b.sreqMileageUnitCost)
        // return b.sreqUnitCost * b.sreqBowserCount + b.sreqMileageUnitCost
        return cashConverter(b.sreqUnitCost * b.sreqBowserCount) 
    }

    const calcPricePay = (b) => {
        console.log("b", b)
        console.log("sreqUnitCost", b.sreqUnitCost)
        console.log("sreqBowserCount", b.sreqBowserCount)
        console.log("sreqMileageUnitCost", b.sreqMileageUnitCost)
        return b.sreqUnitCost * b.sreqBowserCount 
    }


    const [prodd, setProdd] = useState()
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

 

    useEffect(() => {
        dispatch(getExhausterRequests({
            wacWtrId: 1,
            usrId: user.usrId,
            sreqType: 'EXHAUSTER'
        }));


        if (isSuccess && message) {
            toast.success("Exhauster request submitted successfully");
        }
        if (!waterCompanies?.length) {
            dispatch(getWaterCompanies())
        }

        return () => {
            dispatch(reset());
        }
    }, [toast, message, dispatch])

    return (
        <>
            <main role="main" class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 main-window">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-1">
                    <h4 class="page-intros">Exhauster services</h4>
                </div>

                <div class="row main-deck">

                    <div class="col-12 col-md-5">
                        <div class="card">
                            <div class="card-body">


                                <h4 class="water-blue">Request Exhauster</h4>
                                <div class="row"> 
                                </div>

                                <div class="the-form mt2">
                                    <form onSubmit={handleSubmit} class="mt2">

                                        <div class="form-group">
                                            <label for="phone">Contact phone number</label>
                                            <input type="text" name="phone"
                                                value={phone ? phone : user.usrUsername}
                                                onChange={handleChange} class="form-control"
                                                id="phone" aria-describedby="emailHelp"
                                                placeholder="Contact phone number" />
                                        </div>

                                        <div class="form-group" >
                                            <label for="exampleFormControlSelect1">Exhauster ownership type</label>
                                            <div>
                                                <select class="form-control" id="exampleFormControlSelect1" name='ownership' value={ownership}
                                                    onChange={handleChange}>
                                                    <option disabled value="">-- Select --</option>
                                                    {vehicle?.length && vehicle?.map(car => (
                                                        <option value={car.id} key={car.id}>{car.value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <label class="mt1">Number of Exhausters </label>
                                        <div class="input-group mb-3 text-center ">
                                            <div class="input-group-prepend">
                                                <button type='button' onClick={handleDecrement} class="btn btn-silver btn-sm" id="minus-btn"><i class="fa fa-minus"></i></button>
                                            </div>
                                            <input type="number" id="qty_input" class="text-center form-control form-control-sm" value={exhausters} min="1" />
                                            <div class="input-group-prepend">
                                                <button type='button' onClick={handleIncrement} class="btn btn-silver btn-sm" id="plus-btn"><i class="fa fa-plus"></i></button>
                                            </div>
                                        </div>

                                        <div class="form-group mt2">
                                            <label for="location">Location</label>
                                            <input type="text" name="location"
                                                value={location}
                                                onChange={handleChange} class="form-control" id="location"
                                                placeholder="eg karen" required />
                                        </div>

                                        <div class="form-group mt2">
                                            <label for="date">Date expected</label>
                                            <input type="date" name="date"
                                                value={date}
                                                onChange={handleChange} class="form-control" id="date"
                                                placeholder="Date expected" required />
                                        </div>

                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Water service providers</label>

                                            <select class="form-control" id="exampleFormControlSelect1" name='wsp' value={wsp} onChange={handleChange}>
                                                <option disabled value="">-- Select --</option>
                                                {waterCompanies?.length && waterCompanies?.map(waterCompany => (
                                                    <option value={waterCompany.wtrId} key={waterCompany.wtrId}>{waterCompany.wtrCompany}</option>
                                                ))}
                                            </select>
                                        </div>

                                        {!isProcessing ? (
                                            <button type="submit" class="mt1 button nav-link">Submit</button>
                                        ) : (
                                            <button style={{ 'textTransform': 'none' }} class="nav-link-light disabled" disabled>
                                                <CircularProgress size={15} color="inherit" />&nbsp; Submitting
                                            </button>
                                        )}

                                    </form>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-5">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">My Requests</h5>
                                <div class="row">
                                    {isLoading ? (<><CardSkeleton/></>) : (
                                        <>
                                            {exhausterRequests?.length && exhausterRequests?.map((exhauster) => (
                                                <>
                                                    <div class="container-fluid mt1">
                                                        <div class="row for-readings pt1">

                                                            <div class="col pb1">
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <div class="row">
                                                                            <div class="col-4 notf-img"><img src={exhausterservices} alt="exhauster_services" /></div>
                                                                            <div class="col-8 pull-right text-right r-date"> {formatMsToDate(exhauster.sreqCreatedDate)}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="row mt0">
                                                                    <div class="col-6 notf">
                                                                        <p>{exhauster.sreqLocation}</p>
                                                                        <p>{exhauster.sreqBowserCount} {(exhauster.sreqVehicleOwnership)} {exhauster.sreqBowserCount ==1 ? 'Exhauster' : 'Exhausters' } </p>
                                                                        <p>{calcPrice(exhauster)}</p>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="row">
                                                                            <div class="col text-right">
                                                                                <span style={{ color: '#b8b8b8;' }}><i class="icon ion-md-checkmark-circle"></i></span> {exhauster.sreqStatus}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card-footer "> {/* notf-approved */}
                                                            <div class="row">
                                                                <div class="col-6 text-left"  onClick={() => setProdd(exhauster)}><a class="nav-link-shy"  
                                                                 onClick={() => handleClickOpen()}
                                                                 href="#">Cancel <i class="icon ion-md-arrow"></i></a></div>
                                                                <div class="col-6 text-right"><a class="nav-link-def" 
                                                                href="#"
                                                                onClick={()=> handlePayments(calcPricePay(exhauster))} 
                                                                >
                                                                    Pay now 
                                                                    <i class="icon ion-md-arrow"></i></a></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </main>

            <DeleteModal open={open}  handleClose={handleClose} prodd={prodd}  sreqType ='EXHAUSTER' usrId ={user.usrId}/>
        </>
    )
}

export default Exhauster