import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = { 
  meterImageLoading: false, 
  
  
};
export const postFormData = createAsyncThunk(
  "applicantSlice/postFormData",
  async (data) => {
    const res = await axios.post(`${url}/usr/postFormData.action`,data);
    return res.data.jsonData;
  }
);

 
 
export const meterImageSlice = createSlice({
  name: "meterImage",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    }, 
   
    
  },
  extraReducers: (builder) => {
    builder
 
      .addCase(postFormData.pending, (state) => {
        state.meterImageLoading = true;
      })
      .addCase(postFormData.fulfilled, (state, action) => {
        state.meterImageLoading = false;  
      })
      .addCase(postFormData.rejected, (state) => {
        state.meterImageLoading = false;
      })
      
     
  },
});

export default meterImageSlice.reducer;
export const {
  handleSidenavFullWidth,  
} = meterImageSlice.actions;
