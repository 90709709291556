import axios from "axios"

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;


// getMpesa
const getSTK = async ({phone,amount,accountref}) => {
    console.log("accountref",accountref)
    console.log("amount",amount)
    console.log("phone",phone)
    const response = await axios.get(`${API_URL}/usr/mpesaPush.action?phone=${phone}&amount=${amount}&accountref=${accountref}`)
    const result = response.data.jsonData;
    response.data.jsonData = [];
    return result;
}


const MpesaService = {
    getSTK,
}
export default MpesaService
