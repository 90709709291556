import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleFailure } from "../utils";
import otherService from "./otherService";

const initialState = {
  otherRequests: [],
  isLoading: false,
  isProcessing: false,
  isSuccess: false,
  isError: false,
  message: "",
  otherPayAmount: "",
  otherPayment: false,
};

export const getOtherRequests = createAsyncThunk(
  "other_requests",
  async (params, thunkAPI) => {
    try {
      return await otherService.getOtherRequests(params);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveOtherRequest = createAsyncThunk(
  "other_request/save",
  async (bowser, thunkAPI) => {
    try {
      return await otherService.saveOtherRequest(bowser);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const otherSlice = createSlice({
  name: "other",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isProcessing = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
    setOtherPayAmount: (state, action) => {
      state.otherPayAmount = action.payload;
    },
    setOtherPayment: (state, action) => {
      state.otherPayment = action.payload;
    },

    cleanOtherAmount: (state) => {
      state.otherPayAmount = initialState.otherPayAmount;
      state.otherPayment = initialState.otherPayment;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOtherRequests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOtherRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.otherRequests = action.payload;
      })
      .addCase(getOtherRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.otherRequests = [];
      })
      .addCase(saveOtherRequest.pending, (state) => {
        state.isProcessing = true;
      })
      .addCase(saveOtherRequest.fulfilled, (state, action) => {
        state.isProcessing = false;
        state.isSuccess = true;
        state.otherRequests.unshift(action.payload);
        state.message = "Other request submitted";
      })
      .addCase(saveOtherRequest.rejected, (state, action) => {
        state.isProcessing = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, setOtherPayAmount, setOtherPayment, cleanOtherAmount } =
  otherSlice.actions;
export default otherSlice.reducer;
