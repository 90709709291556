import React from 'react';
import './landing.css';
import Header from "../../components/header/Header";
import Services from '../../assets/img/services-a.svg';
import LipaMaji from '../../assets/img/colored/lipa_maji.svg';
import somaMeter from '../../assets/img/colored/jisomee_meter.svg';
import Tokens from '../../assets/img/colored/buy_water_tokens.svg'
import Statement from '../../assets/img/colored/view_bill.svg';
import Other from '../../assets/img/colored/other_services.svg';
import Report from '../../assets/img/colored/report_incident.svg';
import Usage from '../../assets/img/colored/water_usage.svg';
import Phone from '../../assets/img/app.svg';
import GoogleBadge from '../../assets/img/google-play-badge.svg';
import Payment from '../../assets/img/payment.svg';
import Apply from '../../assets/img/colored/apply_new_connection.svg';
import Footer from "../../components/footer/Footer";
import { Link } from 'react-router-dom';

function Landing() {
    return (
        <>
            <div className="bg-shape">
                <Header />

                <section className="hero-banner magic-ball">
                    <div className="container">
                        <div className="row align-items-center text-center text-md-left">
                            <div className="col-md-6">
                                <h1 className="proxima w7">All your water services in one place</h1>
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley. </p> */}
                                <Link className="button button-hero nav-link col-4 text-center mt-4" to="/signup">Get
                                    Started</Link>
                            </div>
                            <div className="col-md-6 ">
                                <img className="img-fluid" src={Services} alt="" />
                            </div>
                        </div>
                    </div>
                </section>


                <section className="section-margin generic-margin">
                    <div className="container">
                        <div className="section-intro text-center pb1">
                            <h2 className="proxima w7">Available Services</h2>
                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy</p> */}
                        </div>

                        <div className="row">

                            <div className='col-md-10 offset-md-1'>
                                <div className='row'>

                                    <div className="col-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                                        <div className="service-card text-left">
                                            <div className="service-card-img">
                                                <img className="img-fluid" src={LipaMaji} alt="" />
                                            </div>
                                            <div className="service-card-body">
                                                <h3>Lipa Maji</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                                        <div className="service-card text-left">
                                            <div className="service-card-img">
                                                <img className="img-fluid" src={somaMeter} alt="" />
                                            </div>
                                            <div className="service-card-body">
                                                <h3>Jisomee Meter</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                                        <div className="service-card text-left">
                                            <div className="service-card-img">
                                                <img className="img-fluid" src={Tokens} alt="" />
                                            </div>
                                            <div className="service-card-body">
                                                <h3>Buy Water Tokens</h3>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                                        <div className="service-card text-left">
                                            <div className="service-card-img">
                                                <img className="img-fluid" src={Statement} alt="" />
                                            </div>
                                            <div className="service-card-body">
                                                <h3>Maji Loan</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                                        <div className="service-card text-left">
                                            <div className="service-card-img">
                                                <img className="img-fluid" src={Apply} alt="" />
                                            </div>
                                            <div className="service-card-body">
                                                <h3>Apply for New connection</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                                        <div className="service-card text-left">
                                            <div className="service-card-img">
                                                <img className="img-fluid" src={Statement} alt="" />
                                            </div>
                                            <div className="service-card-body">
                                                <h3>View Bill/Statement</h3>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                                        <div className="service-card text-left">
                                            <div className="service-card-img">
                                                <img className="img-fluid" src={Report} alt="" />
                                            </div>
                                            <div className="service-card-body">
                                                <h3>Report Incident</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                                        <div className="service-card text-left">
                                            <div className="service-card-img">
                                                <img className="img-fluid" src={Usage} alt="" />
                                            </div>
                                            <div className="service-card-body">
                                                <h3>Water Usage</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
                                        <div className="service-card text-left">
                                            <div className="service-card-img">
                                                <img className="img-fluid" src={Other} alt="" />
                                            </div>
                                            <div className="service-card-body">
                                                <h3>Other Services </h3>
                                            </div>
                                        </div>
                                    </div>

                                    

                                </div>
                                
                            </div>
          
                        </div>
                    </div>
                </section>


                <section className="section-margin">
                    <div className="container">
                        <div className="section-intro text-center pb-90px">
                            <h2>Get the Application</h2>
                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy</p> */}
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 offset-xl-1">
                                <div className="search-content text-left">
                                    <img src={Phone} alt="" className="img-fluid" />
                                </div>
                            </div>

                            <div className="col-lg-6 col-xl-5 align-self-center mb-5 mb-lg-0">
                                <div className="search-content">
                                    <h2>Download the App</h2>
                                    <a className="" href="#"> <img className="img-fluid" src={GoogleBadge}
                                                                   alt="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="section-padding">
                    <div className="container">
                        <div className="section-intro text-center pb-90px">
                            <h2>Convenient Payment Options</h2>
                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy</p> */}
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 mb-12 text-center">
                                <div className="about-img text-center payment">
                                    <img className="img-fluid" src={Payment} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <Footer />
            </div>
        </>
    );
}

export default Landing;