import React, {useState, useEffect} from 'react';
import AuthHeader from "../../components/header/AuthHeader";
import state from "../../assets/img/elements/state.svg";
import avatar from "../../assets/img/acc-avatar.svg";
import avatar2 from "../../assets/img/acc-avatar2.svg";
import lipaMaji from "../lipa-maji/LipaMaji";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addAccounts, searchAccounts} from "../../features/Account/accSlice";
import {Button, CircularProgress, FilledInput, InputLabel, OutlinedInput, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppBar from '../../components/header/AppBar';
import { getWaterCompanies } from '../../features/wsp/wspSlice';
import { getAccounts, reset } from '../../features/Account/accSlice';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const Accounts = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);
    // const { accounts, isLoading } = useSelector((state) => state.acct)
    
    const { accounts, searchAccs, isLoading, isAddLoading, isProcessing, isSearching, isSearchSuccess, isAddSuccess, isSearchError, isAddError,  isError, isSuccess, message } = useSelector(state => state.acc)
    const { waterCompanies,  } = useSelector(state => state.wsp);
    const [waterCompany, setWaterCompany] = useState('');
    const [account,setAccount]= useState('');
    const [formData,setFormData]= useState({
        acc_number :'',
        wsp: ''
    });

    const { acc_number, wsp} = formData;

    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name] : e.target.value
        }))
    }

 
const [processingAcc,setProcessingAcc] =  useState(false)
    const searchAccount =async () => { 
        setProcessingAcc(true);
        if (!acc_number) {
            toast.warning("Please fill out account number");
        } else if (!wsp) {
            toast.warning("Please select water service provider");
        } else {
        
            const formData = {
                cAccNumber: acc_number,
                wacWtrId: wsp,
            }

            const res =  await dispatch(searchAccounts(formData))
         

            if(res?.payload?.jsonData?.length > 0){
                const account1 = {
                    wacAccNumber: acc_number,
                    wacAccId:res?.payload?.jsonData[0].cAccId, 
                    wacWtrId: wsp,
                    wacCustName: res?.payload?.jsonData[0].cAccountName,
                    wacWtrCode: getWSPCode(wsp),
                    wacUsrId: user.usrId,
                 } 
                // toast.info(`Account ${acc_number} was found in ${getWSPCode(wsp)}.`)
              const res2 = await dispatch(addAccounts(account1)) 
              if(res2?.payload?.wacId){
                toast.success(`Account ${acc_number} created successfully`)
              }else{
                toast.warning(`Error creating Account ${acc_number}`)
              }
              getAccountsData(); 
            }else{
                toast.warning(`Account ${acc_number} does not exist.`)
            }

            setProcessingAcc(false);

    }


    }
 
    // const saveAccount = async (e) => {

    //     if (!acc_number) {
    //         toast.warning("Please fill out account number");
    //     } else if (!wsp) {
    //         toast.warning("Please select water service provider");
    //     } else {
        
       
        
    //     e.preventDefault()
      
    //     const account = {
    //         wacAccNumber: acc_number,
    //         // wacAccId:122, 
    //         // wacWtrId: wsp,
    //         // wacCustName: `${user.usrFirstName} ${user.usrLastName}`,
    //         // wacWtrCode: getWSPCode(wsp),
    //         // wacUsrId: user.usrId,
    //      }
     

    //      if(searchAccs && !isSearching){
    //     dispatch(addAccounts(account))
    //      }

    //     if (!isProcessing ) {
    //         toast.success(message)
    //         setFormData((prevState) => ({
    //             ...prevState,
    //             acc_number :'',
    //             wsp: ''
    //         }))
    //     }

    //     }
    // }

    const getWSPCode = (wsp) => {
        if (!wsp || !waterCompanies.length) return '';
        let _wsp = waterCompanies.filter(row => row.wtrId == wsp)[0];
        return _wsp.wtrCode;

    }

    async function getAccountsData(){
        await dispatch(getAccounts());
    }

    useEffect(() => {
        if (!user) navigate('/')
        // if (!waterCompanies.length) {
            dispatch(getWaterCompanies())
        // }

        getAccountsData();

 

        return () => {
            dispatch(reset());
        }

    }, [dispatch, navigate])

    
    return (
        <>
            <AppBar />

            {isLoading ? (<p>Loading ...</p>) : (
                <>
                    <div className="row no-gutters">

                    <div className="col-12 col-md-6 acs-blue acs-height">
                        <div className="row no-gutters guttrs-xs">
                            <div className="col-md-6 offset-md-3">
                                <h4>Account Wizard</h4>
                                <p>Set up your water account</p>

                                <div className="the-form mt2 pt3">
                                    <form className="mt2">

                                        <div className="form-group">
                                            <input
                                                onChange={handleChange}
                                                type="text"
                                                className="form-control"
                                                aria-describedby="emailHelp"
                                                placeholder="Account number"
                                                name='acc_number'
                                                value={acc_number}
                                                required />
                                        </div>

                                        <div class="form-group mt2">
                                            {/* <label for="exampleFormControlSelect1">Water service providers</label> */}
                                            <select class="form-control" id="exampleFormControlSelect1" name='wsp' onChange={handleChange} required>
                                                <option style={{backgroundColor:'#412a21'}} value="" selected={wsp==''}>Select water service provider</option>
                                                {waterCompanies.length &&  waterCompanies.map(waterCompany => (
                                                    <option style={{backgroundColor:'#412a21'}} value={waterCompany.wtrId} key={waterCompany.wtrId} >{waterCompany.wtrCompany}</option>
                                                ))}
                                            </select>
                                        </div>

                                        
                                        
                                        {!processingAcc ? (
                                            <button type="button" className="mt1 col filter-btns-others add-acc"
                                            // onClick={saveAccount}>Add new Account</button>
                                            onClick={searchAccount}>Add new Account</button>
                                            
                                        ) : (
                                            <button style={{'textTransform': 'none'}} class="mt1 col filter-btns-others filter-btns-processing disabled" disabled>
                                                <CircularProgress size={15} color="inherit" />&nbsp; Processing ...
                                            </button>
                                            
                                        )}

                                        {/* {!isSearching ? (
                                            <button type="button" className="mt1 col filter-btns-others search-acc"
                                            onClick={searchAccount}>Search account</button>
                                        ) : (
                                            <button style={{'textTransform': 'none'}} class="mt1 col filter-btns-others filter-btns-processing disabled" disabled>
                                                <CircularProgress size={15} color="inherit" />&nbsp; Processing ...
                                            </button>
                                            
                                        )} */}


                                    </form>
                                </div>

                                <p className="text-center acs-link pt3">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    {/* <a href="#">My water company is missing!</a> */}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 acs-height3">
                        <div className="row no-gutters guttrs-xs">
                            <div className="col-md-6 offset-md-3">
                                <h4>My Accounts</h4>

                                


                                <div className="row pt2">
                                    
                                    { !accounts.length ? (
                                        <div className="col state-empty pt2">
                                            <div className="row">
                                                <img src={state} alt="" />
                                            </div>
                                            <p className="mt1 text-center">There are no accounts added yet!</p>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <p className="nav-link text-center pt3"><Link to='/home'>Proceed to home<i
                                                className="icon ion-md-arrow"></i></Link></p>
                                        </div>
                                    ) : ( 
                                        <>
                                        {accounts.map(account => (
                                            <div className="col-12">
                                            <div className="accounts-profile mt1">
                                                <div className="row">
                                                    <div className="col-2 ac-avatar text-center mt1">
                                                        <img src={avatar2} alt="" />
                                                    </div>
                                                    <div className="col-10 ac-info mb1 mt1">
                                                        <h6>Account from: {account.wacWtrCode}</h6>
                                                        <p className="ac-details"> {account.wacAccNumber} | <span>{account.wacCustName}</span></p>
                                                    </div>
                                                    {/* <div className="col-2 ac-delete pt2">
                                                        <a href="#"><i className="icon ion-md-trash"></i></a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                        
                                        </>
                                    )}

                                    {/* <div className="col-12">
                                        <div className="accounts-profile mt1">
                                            <div className="row">
                                                <div className="col-2 ac-avatar text-center">
                                                    <img src={avatar2} alt="" />
                                                </div>
                                                <div className="col-8 ac-info mb1 mt1">
                                                    <h6>Account</h6>
                                                    <p className="ac-details"> 456456354 | <span>Nairobi</span></p>
                                                </div>
                                                <div className="col-2 ac-delete pt2">
                                                    <a href="#"><i className="icon ion-md-trash"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>


                            </div>
                        </div>
                    </div>


                    </div>
                </>
            )}
        </>
    );
};

export default Accounts;