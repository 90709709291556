import {
  Box,
  Button,
  Card,
  CardActions,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import image from "../../../assets/apply_new_connection.svg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { getNewConnection } from "../../../features/apply_new_connection/applyNewConnectionSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const formatMsforHumans = (timestamp) => {
  if (!timestamp) return;
  let date = new Date(timestamp);

  let year = date.getFullYear();
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let day = ("0" + date.getDate()).slice(-2);

  return `${day}/${month}/${year}`;
};
export default function TopRightCard() {
  const commonStyles = {
    bgcolor: "background.paper",
    m: 1,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const { applicationDetails, isLoading, isError, isSuccess, message,isLoadingNewCon } =
    useSelector((state) => state.newConnection);
  useEffect(() => {
    if (isError) {
      console.log(message);
    }
    dispatch(
      getNewConnection({
        wacWtrId: 1,
        usrId: user.usrId,
        //   usrId: 1980,
      })
    );
  }, [navigate, isError, message, dispatch]);

  return (
    <div style={{ padding: "16px" }}>
      {/* {applicationDetails.length && applicationDetails.slice(0,2).map((detail,index)=>( */}

      {applicationDetails == null ? (
        <>
          <p>No data</p>
        </>
      ) : (
        <>
          {isLoadingNewCon ? (
            <>Loading ..</>
          ) : (
            <>
              {applicationDetails?.length &&
                applicationDetails?.map((detail, index) => (
                  <>
                    <Card style={{ marginBottom: "15px" }}>
                      <Grid container spacing={2} style={{ padding: "16px" }}>
                        <Grid item xs={9}>
                          <Typography
                            variant="paragraph"
                            style={{ color: "#2A2A2A" }}
                          >
                            {detail?.aorServices}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="paragraph"
                            style={{ color: "#707070" }}
                          >
                            Applied on:{" "}
                          </Typography>
                          <Typography
                            variant="paragraph"
                            style={{ color: "#707070" }}
                          >
                            {formatMsforHumans(detail?.aorCreatedDate)}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="paragraph"
                            style={{ color: "#707070" }}
                          >
                            <span>
                              <CheckCircleOutlineIcon
                                color={
                                  detail?.aorStatus === "SUBMITTED"
                                    ? "success"
                                    : "warning"
                                }
                                sx={{ fontSize: "13px" }}
                              />
                            </span>
                            {detail?.aorStatus}
                          </Typography>
                        </Grid>
                      </Grid>
                      <CardActions
                        style={{
                          backgroundColor:
                            detail?.aorStatus === "SUBMITTED"
                              ? "#4156A6"
                              : "#B8B8B8",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          style={{
                            backgroundColor:
                              detail?.aorStatus === "SUBMITTED"
                                ? `var(--water-blue)`
                                : "#ffffff",
                            color:
                              detail?.aorStatus === "SUBMITTED"
                                ? "#FFFFFF"
                                : "#024172",
                            border: "green",
                            outline: "1px solid green",
                          }}
                        >
                          {detail?.aorStatus === "SUBMITTED"
                            ? "PAY NOW"
                            : "CANCEL"}
                        </Button>
                      </CardActions>
                    </Card>
                  </>
                ))}
            </>
          )}
        </>
      )}
    </div>
  );
}
