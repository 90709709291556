import React from 'react'
import { Link } from 'react-router-dom';
import BowserServices from '../../img/colored/bowser_services.svg';
import AppBar from '../header/AppBar';
import { useLocation } from 'react-router-dom';

const Menu = () => {
  const location = useLocation();

  const isActive = (link) => {
    const path = location.pathname.split("/")[1];
    if (link == path) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
     {/* <AppBar />

     <div class="container-fluid">
      <div class="row"> */}

        <nav class="col-md-2 d-none d-md-block bg-light sidebar" style={{position:'fixed'}}>
          <div class="sidebar-sticky">
            <ul class="nav flex-column">
              <li class="nav-item">
                <Link  
                  className={`lipa-maji nav-link ${isActive('lipa-maji') && 'active'}`} 
                  to="/lipa-maji"
                >
                  Lipa Maji
                </Link>
              </li>
              <li class="nav-item">
                <Link 
                  className={`jisomee-meter nav-link ${isActive('jisomee-meter') && 'active'}`} 
                  to="/jisomee-meter"
                >
                  Jisomee Meter
               </Link>
             </li>
             {/* <li class="nav-item">
              <Link 
                className={`water-token nav-link ${isActive('buy-tokens') && 'active'}`}
                to="/buy-tokens"
              >
                Buy water tokens 
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className={`maji-loan nav-link ${isActive('maji-loan') && 'active'}`}
                to="/maji-loan"
              >
                  Maji Loan
              </Link>
            </li> */}
            <li class="nav-item">
              <Link 
                className={`bowser nav-link ${isActive('bowser') && 'active'}`}
                to="/bowser"
              >
                Bowser Services
              </Link>
            </li>
            <li class="nav-item">
              <Link 
                className={`exhauster nav-link ${isActive('exhauster') && 'active'}`}
                to="/exhauster"
              >
                Exhauster Services
              </Link>
            </li>
            <li class="nav-item">
              <Link 
                className={`apply nav-link ${isActive('connect') && 'active'}`}
                to="/connect"
              >
                New connection
              </Link>
            </li>
            <li class="nav-item">
              <Link 
                className={`bill nav-link ${isActive('view-bill') && 'active'}`}
                to="/view-bill"
              >
                View Bill/Statement
              </Link>
            </li>
            <li class="nav-item">
              <Link 
                className={`services nav-link ${isActive('other-services') && 'active'}`}
                to="/other-services">
                Other Services
              </Link>
            </li>

            <li class="nav-item">
              <Link 
                className={`report nav-link ${isActive('report-incidence') && 'active'}`}
                to="/report-incidence">
                Report Incident
              </Link>
            </li>
         </ul>


         <ul class="nav flex-column extra-nav">
          <li class="nav-item">
            <a class="nav-link" href="#">
              About Us
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
             Terms & Conditions
           </a>
         </li>
        <li class="nav-item">
          <a class="nav-link " href="#">
            Privacy Policy
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link " href="#">
            Help
          </a>
        </li>
      </ul>

    </div>
        </nav>

        

      {/* </div>
    </div>
        */}
    </>
  )
}

export default Menu