import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Avatar,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import avatar from "../../img/avatar.jpg";
import acc from "../../img/acc-avatar.svg";
import "./viewBill.css";
import {
  AccountCircle,
  Add,
  AddCircleOutlineOutlined,
  CalendarMonth,
  DeleteOutlineTwoTone,
  DeleteTwoTone,
} from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import ViewBillDialog from "../../components/view_bill_dialog/ViewBillDialog";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getMonthBill,
  getPeriods,
  getViewStatement,
  reset,
} from "../../features/view_bill/viewBillSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ViewStatementDialog from "../../components/view_statement_dialog/ViewStatementDialog";
import { getAccounts } from "../../features/accounts/accountSlice";

const currencies = [
  {
    value: "0",
    label: "1",
  },
  {
    value: "1",
    label: "2",
  },
  {
    value: "2",
    label: "3",
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const months = [
  {
    value: "0",
    label: "0",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
];

function ViewBill() {
  const formatMsforHumans = (timestamp) => {
    if (!timestamp) return;
    let date = new Date(timestamp);

    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);

    return `${day}/${month}/${year}`;
  };

  const handleClick = (e) => {};
  const [value, setValue] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [month, setMonth] = useState("");
  const [startDates, setStartDates] = useState(null);
  const [endDates, setEndDates] = useState(null);
  const handleClickCalendar = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const [openBill, setOpenBill] = useState(false);
  const handleOpenBill = () => {
    handleViewBill();
    setOpenBill(true);
  };

  const handleCloseBill = () => {
    setOpenBill(false);
  };

  const [openStatement, setOpenStatement] = useState(false);
  const handleOpenStatement = () => {
    handleViewStatement();
    setOpenStatement(true);
  };

  const handleCloseStatement = () => {
    setOpenStatement(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  console.log("user", user);
  const {
    periods,
    viewStatement,
    monthBill,
    isLoading,
    isLoadingBill,
    isLoadingViewStatement,
    isError,
    isErrorBill,
    isErrorViewStatement,
    isSuccess,
    isSuccessBill,
    isSuccessViewStatement,
    message,
  } = useSelector((state) => state.periods);

  const { accounts } = useSelector((state) => state.acct);
  useEffect(() => {
    if (isError) {
      console.log(message);
    }
    dispatch(
      getPeriods({
        wacWtrId: 1,
      })
    );
    dispatch(
      getAccounts({
        // usrId:  1979,
        usrId: user.usrId,
      })
    );
  }, [navigate, isError, message, dispatch]);

  console.log("accounts", accounts);

  const handleViewBill = () => {
    dispatch(
      getMonthBill({
        // WaterCompanies /service providers
        wacWtrId: 1,
        // account
        wacAccId: account.account,
        wacPdId: month.month,
      })
    );
  };

  const handleViewStatement = () => {
    dispatch(
      getViewStatement({
        // wacAccId: 50840,
        wacAccId: account.account,
        wacWtrId: 1,
        startDate: formatMsforHumans(startDates),
        endDate: formatMsforHumans(endDates),
        wacEmail: "",
      })
    );
  };

  const [account, setAccount] = useState("");

  return (
    <main
      role="main"
      class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 main-window"
    >
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-1">
        <h4 class="page-intros">View Bill/ Statement</h4>
      </div>
      {isLoading ? (
        <p>Loading ....</p>
      ) : (
        <>
          <h4 className="page-intros"></h4>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Card style={{ padding: "1rem", marginBottom: "2.5rem" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Chip
                      label="Edit"
                      variant="outlined"
                      onClick={handleClick}
                      style={{ color: "#007bff" }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {user ? (
                    <>
                      <Grid item xs={4} md={3}>
                        <Avatar
                          {...stringAvatar(
                            `${user.usrFirstName} ${user.usrLastName}`
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <h5>
                          {user.usrFirstName} {user.usrLastName}
                        </h5>
                        <div>Phone: {user.usrMobileNumber}</div>
                        <div>Email: {user.usrEmail}</div>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={4} md={3}>
                        <div>
                          <img
                            style={{
                              height: "100px",
                              width: "100px",
                              borderRadius: "50%",
                              overflow: "hidden",
                              textAlign: "center",
                              margin: "auto",
                            }}
                            src={avatar}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <h5>Jane Doe</h5>
                        <div>Phone: 0798 423 232</div>
                        <div>Email: janedoe@gmail.com</div>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Card>

              <div>
                {!account ? (
                  <Grid container spacing={2} style={{ marginBottom: "15px" }}>
                    <Grid item xs={12} md={12}>
                      <Card style={{ padding: "15px" }}>
                        <p>
                          Select account to view Billing history & Statement
                        </p>
                        <TextField
                          id="outlined-select-account"
                          select
                          fullWidth
                          name="account"
                          label="Select Account"
                          variant="outlined"
                          onChange={(e) => {
                            setAccount((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value,
                            }));
                          }}
                        >
                          {accounts.map((option) => (
                            <MenuItem
                              key={option.wacAccId}
                              value={option.wacAccId}
                            >
                              {option.wacAccNumber}
                              <Divider orientation="vertical" sx={{ mx: 2 }} />
                              {option.wacCustName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Card>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "15px" }}
                    >
                      <Grid item xs={12} md={12}>
                        <Card style={{ padding: "15px" }}>
                          <p>
                            Select account to view Billing history & Statement
                          </p>
                          <TextField
                            id="outlined-select-account"
                            select
                            fullWidth
                            name="account"
                            label="Select Account"
                            variant="outlined"
                            onChange={(e) => {
                              setAccount((prevState) => ({
                                ...prevState,
                                [e.target.name]: e.target.value,
                              }));
                            }}
                          >
                            {accounts.map((option) => (
                              <MenuItem
                                key={option.wacAccId}
                                value={option.wacAccId}
                              >
                                {option.wacAccNumber}
                                <Divider
                                  orientation="vertical"
                                  sx={{ mx: 2 }}
                                />
                                {option.wacCustName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Card>
                      </Grid>
                    </Grid>

                    <h6 style={{ color: "#4156a6" }}>
                      View your Billing History
                    </h6>
                    <Card
                      class="accounts-profile"
                      style={{ padding: "0.5rem 1.5rem", marginBottom: "1rem" }}
                    >
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{ display: "flex", alignItems: "flex-end" }}
                        >
                          <CalendarMonth
                            sx={{ color: "action.active", mr: 1, my: 0.5 }}
                          />
                          <TextField
                            id="input-with-sx"
                            select
                            fullWidth
                            name="month"
                            label="Select month for bill"
                            variant="standard"
                            onChange={(e) => {
                              setMonth((prevState) => ({
                                ...prevState,
                                [e.target.name]: e.target.value,
                              }));
                            }}
                            SelectProps={{
                              MenuProps: {
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "center",
                                },
                                getContentAnchorEl: null,
                              },
                            }}
                          >
                            <MenuItem disabled value="">
                              <em>Select month for bill</em>
                            </MenuItem>
                            {periods.length &&
                              periods.map((option) => (
                                <MenuItem key={option.pdId} value={option.pdId}>
                                  {option.pdCode}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>

                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            marginTop: "3rem",
                          }}
                        >
                          {!month ? (
                            <Button
                              disabled
                              variant="contained"
                              sx={{
                                width: "100%",
                                border: "none",
                                textTransform: "none",
                                outline: "2px solid transparent",
                                outlineOffset: "2px",
                                backgroundColor: `var(--water-blue)!important`,
                                color: "white !important",
                                borderRadius: "0.5rem",
                                fontWeight: "600",
                                paddingLeft: "3rem",
                                paddingRight: "3rem",
                                paddingTop: "0.5rem",
                                paddingBottom: "0.5rem",
                              }}
                              onClick={handleOpenBill}
                            >
                              View Bill
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              sx={{
                                width: "100%",
                                border: "none",
                                textTransform: "none",
                                outline: "2px solid transparent",
                                outlineOffset: "2px",
                                backgroundColor: `var(--water-blue)!important`,
                                color: "white !important",
                                borderRadius: "0.5rem",
                                fontWeight: "600",
                                paddingLeft: "3rem",
                                paddingRight: "3rem",
                                paddingTop: "0.5rem",
                                paddingBottom: "0.5rem",
                              }}
                              onClick={handleOpenBill}
                            >
                              View Bill
                            </Button>
                          )}
                        </div>
                      </Grid>
                    </Card>
                  </>
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={7}>
              <Card style={{ padding: "1rem" }}>
                <h6 style={{ color: "#4156a6" }}>Get your Statement </h6>
                <Card
                  class="accounts-profile"
                  style={{ padding: "1rem 1.5rem", marginBottom: "1rem" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <CalendarMonth sx={{ marginRight: "5px" }} />
                        Start Date
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: "1rem" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            inputFormat="DD-MM-YYYY"
                            value={startDates}
                            onChange={(newValue) => {
                              setStartDates(newValue);
                            }}
                            PopperProps={{
                              placement: "bottom-end",
                            }}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                size="small"
                                sx={{
                                  "& fieldset": { border: "none" },
                                  backgroundColor: "#024172",
                                  borderRadius: "10px !important",
                                  svg: { color: "#ffc400" },
                                  input: { color: "#fff" },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div>to</div>
                    </Grid>
                    <Grid item xs={5}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <CalendarMonth sx={{ marginRight: "5px" }} />
                        End Date
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: "1rem" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            inputFormat="DD-MM-YYYY"
                            value={endDates}
                            onChange={(newValue) => {
                              setEndDates(newValue);
                            }}
                            PopperProps={{
                              placement: "bottom-end",
                            }}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                size="small"
                                sx={{
                                  "& fieldset": { border: "none" },
                                  backgroundColor: "#024172",
                                  borderRadius: "10px !important",
                                  svg: { color: "#ffc400" },
                                  input: { color: "#fff" },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div style={{ marginTop: "2rem" }}>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="john@doe.com"
                    ></input>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      marginTop: "3rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={!startDates || !endDates ? true : false}
                      sx={{
                        width: "100%",
                        border: "none",
                        textTransform: "none",
                        outline: "2px solid transparent",
                        outlineOffset: "2px",
                        backgroundColor: `var(--water-blue)!important`,
                        color: "white !important",
                        borderRadius: "0.5rem",
                        fontWeight: "600",
                        paddingLeft: "3rem",
                        paddingRight: "3rem",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem",
                      }}
                      onClick={handleOpenStatement}
                    >
                      Get Statement
                    </Button>
                  </div>
                </Card>
              </Card>
            </Grid>
          </Grid>
        </>
      )}

      <ViewBillDialog
        open={openBill}
        monthBill={monthBill}
        isLoadingBill={isLoadingBill}
        handleOpenBill={handleOpenBill}
        handleCloseBill={handleCloseBill}
      />
      <ViewStatementDialog
        open={openStatement}
        viewStatement={viewStatement}
        isLoadingViewStatement={isLoadingViewStatement}
        handleOpenStatement={handleOpenStatement}
        handleCloseStatement={handleCloseStatement}
      />
    </main>
  );
}

export default ViewBill;

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      fontSize: "40px",
      width: 80,
      height: 80,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}
