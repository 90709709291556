import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;
 
const getBowserRequests = async({ wacWtrId, usrId, sreqType})=>{
    const response = await axios.get(`${API_URL}/usr/fetchServiceRequest.action?wacWtrId=${wacWtrId}&usrId=${usrId}&sreqType=${sreqType}&sreqAccId=`, {
        headers: {'Accept': 'application/json'}   
    })

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.jsonData
}

const saveBowserRequest = async (bowser) => {
    const response = await  axios.post(`${API_URL}/usr/postServiceRequest.action?wacWtrId=${bowser.sreqWaterProviderId}`, bowser, {
        headers: {'Accept': 'application/json'}
    })
    // return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
    return response.data;
}

const cancelBowserRequests = async(bowser)=>{
    const response = await axios.post(`${API_URL}/usr/cancelServiceRequest.action?wacWtrId=${bowser.sreqWaterProviderId}`,bowser, {
        headers: {'Accept': 'application/json'}   
    })

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.jsonData
}

const bowserService = {
    getBowserRequests,
    saveBowserRequest,
    cancelBowserRequests
}
export default bowserService
